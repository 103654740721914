.registration_main {
    height: 1px;
    display: flex;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    background-size: cover;
    justify-content: flex-start;
    background-image: radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%);
    background-repeat: no-repeat;
}

.registration_card {
    min-width: 300px;
    margin-top: 6em;
}

.registration_form {
    display: block;
    padding: 0 1em 1em 1em;
}

.registration_form div {
    display: block;
    margin-top: 1em;
}
.registration_input {

}
.registration_button {
    width: 100%
}

.registration_avatar {
    margin: 1em;
    display: flex;
    justify-content: center;
}

.registration_icon {
    margin-right: 1em;
}